@import '../../../../../styles/customMediaQueries.css';

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.linkMenuLabelWrapper {
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  margin: 28px 0;
  text-decoration: inherit;
}

.linkMenuLabel {
  flex-shrink: 0;

  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
    color: var(--colorBlack);
  }

  &:active {
    border-bottom: 0;
  }
}

.linkMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.arrowIcon {
  margin-left: 8px;
}

.linkMenuContent {
  min-width: 276px;
  padding: 20px 0;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.primaryLinks {
  padding-bottom: 5px; 
}

.lastPrimaryLinks {
  border-bottom: 1px solid var(--colorGrey100);
  padding-bottom: 5px; 
}

.links {
  padding-top: 5px;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 25px;
  right: 115px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.notificationBadge {
  position: absolute;
  top: 25px;
  right: 155px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.linkContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Aradaki boşluk */
}

.iconStyle {
  width: 25px;
}